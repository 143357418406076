import React from 'react'
import {Link}  from 'react-router-dom'
import './style.css'

const HeaderTopbar = () => {
    return(	
        <div className="topbar">
            <div className="container">
                <div className="row">
                    <div className="col col-md-6 col-sm-12 col-12">
                        <div className="contact-intro">
                            <ul>
                                <li><i className="fi flaticon-call"></i>+1 (514) 589-9255</li>
                                <li><i className="fi flaticon-envelope"></i>info@westislandhalaqah.org</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-md-6 col-sm-12 col-12 d-flex justify-content-end">
                        <div className="contact-info">
                                <a className="theme-btn" href="https://donate.stripe.com/14k7t2gldg5BenS6oo">Donate Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopbar;