import React from 'react'
import icon1 from '../../images/course/icon1.png'
import icon2 from '../../images/course/icon2.png'
import icon3 from '../../images/course/icon3.png'
import icon4 from '../../images/course/icon4.png'
import icon5 from '../../images/course/icon5.png'
import icon6 from '../../images/course/icon6.png'

import './style.css'

const Course = (props) => {

    const courses = [
        {
          cIcon:icon1,
          heading:"Youth Programs",
          pra:"Tailored for younger members, combining Islamic education with engaging activities and sports, fostering a sense of community and spiritual growth in a fun environment.",
        },
        {
          cIcon:icon2,
          heading:"Sports & Recreation",
          pra:"Organizes group sports and recreational activities, promoting physical health, teamwork, and Islamic brotherhood/sisterhood.",
        },
        {
          cIcon:icon3,
          heading:"Community Events & Gatherings",
          pra:"Organizes group sports and recreational activities, promoting physical health, teamwork, and Islamic brotherhood/sisterhood.",
        },
        {
          cIcon:icon4,
          heading:"Halaqah Khutbahs",
          pra:"Regular sessions for spiritual upliftment through khutbahs and sermons, addressing contemporary issues and Islamic guidance.",
        },
        {
          cIcon:icon5,
          heading:"Educational Workshops & Conferences",
          pra:"Features lectures, workshops, and conferences on various Islamic and contemporary topics, delivered by knowledgeable speakers.",
        },
        {
          cIcon:icon6,
          heading:"Youth Empowerment & Community Service",
          pra:"Nurturing leadership and community service among the youth. It includes volunteer opportunities, community service projects, and leadership training workshops.",
        },
    ]
    return(
        <div className="courses-area">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="wpo-section-title">
                            <h2>Our Activities</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {courses.map((cource, i) => (
                        <div className="col-lg-4 col-md-6 custom-grid col-12" key={i}>
                            <div className="courses-item">
                                <div className="course-icon">
                                    <span><img src={cource.cIcon} alt="" /></span>
                                </div>
                                <div className="course-text">
                                    <h2>{cource.heading}</h2>
                                    <p>{cource.pra}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Course;