import React from 'react'
import {Link} from 'react-router-dom'


const EventSection2 = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }


     const eventList = [
         {
             eventImg:props.eventImg1,
             date:"28",
             month:"OCT",
             title:"Family Matters",
             time:"18.00 - 20.00",
             location:"Centre Canadien Islamique Al Jamieh",
             des:"Discover the Power of Family at the \"Family Matters\" Islamic Conference. Some of the topics covered include Nurturing Social Circles, Keeping Ties of Kinship and Ihsan Towards Parents",
             btn:"Learn More...",
             link:"https://www.zeffy.com/fr-CA/ticketing/d9e01666-92f2-4887-b2e3-70c329778c4b",
         },
         {
             eventImg:props.eventImg2,
             date:"16",
             month:"SEP",
             title:"The Straight Path",
             time:"18.00 - 20.00",
             location:"Centre Canadien Islamique Al Jamieh",
             des:"Addiction, Habits, Friendship, Brotherhood & Sisterhood, Daily Fitna",
             btn:"Learn More...",
             link:"https://www.zeffy.com/ticketing/4d5f892d-0aa2-4bc0-b462-ba2910f419ce",
         },
     ]

    return(
        <div className={`wpo-event-area section-padding  ${props.EventClass}`}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="wpo-section-title">
                            <span>Our Events</span>
                            <h2>Our Upcoming Events</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {eventList.map( (item, i) => (
                        <div className="col-lg-6 col-sm-12 col-12 custom-grid" key={i}>
                            <div className="wpo-event-item">
                                <div className="wpo-event-img">
                                    <img src={item.eventImg} alt="" />
                                    <div className="thumb-text">
                                        <span>{item.date}</span>
                                        <span>{item.month}</span>
                                    </div>
                                </div>
                                <div className="wpo-event-text">
                                    <h2>{item.title}</h2>
                                    <ul>
                                        <li><i className="fa fa-clock-o" aria-hidden="true"></i>{item.time}</li>
                                        <li><i className="fa fa-map-marker"></i>{item.location}</li>
                                    </ul>
                                    <p>{item.des}</p>
                                    <a href={item.link}>{item.btn}</a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default EventSection2;