import React from 'react';
import './style.css';
// You can also include the Mailchimp CSS if needed

const Newsletter = (props) => {

    return (
        <section className="wpo-news-letter-section">
            <div className="container">
                <div className="wpo-news-letter-wrap">
                    <div className="row">
                        <div className="col col-lg-10 offset-lg-1 col-md-8 offset-md-2">
                            <div className="wpo-newsletter">
                                <h3>Subscribe To Our Newsletter</h3>
                                <p>Stay up to date with our new events or activities</p>
                                <div className="wpo-newsletter-form">
                                    <form
                                        action="https://gmail.us21.list-manage.com/subscribe/post?u=e955f55efca1d29bd20ba41ba&amp;id=5014d672b4&amp;f_id=008be5e6f0"
                                        method="post"
                                        id="mc-embedded-subscribe-form"
                                        name="mc-embedded-subscribe-form"
                                        className="validate"
                                        target="_blank">

                                        <div className="grid-container">
                                            <input type="email" name="EMAIL" className="required email grid-item"
                                                   placeholder="Enter Your Email" required/>
                                            <input type="text" name="FNAME" placeholder="First Name"
                                                   className="text grid-item"
                                                   id="mce-FNAME"/>
                                            <input type="text" name="LNAME" placeholder="Last Name"
                                                   className="text grid-item"
                                                   id="mce-LNAME"/>
                                            <input type="text" name="PHONE" placeholder="Phone Number"
                                                   className="text grid-item"
                                                   id="mce-PHONE"/>
                                        </div>
                                        <div>
                                            <button type="submit" name="subscribe" id="mc-embedded-subscribe"
                                                    className="button">Subscribe
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Newsletter;
