import React from 'react'
import {Link} from 'react-router-dom'
import tmr1 from '../../images/prayer-shape/2.png'
import tmr2 from '../../images/prayer-shape/1.png'
import tmr3 from '../../images/prayer-shape/3.png'
import VideoModal from '../ModalVideo'
import './style.css'

const About = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="wpo-about-area section-padding">
            <div className="container">
                <div className="wpo-about-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="wpo-about-img-3">
                                <img src={props.aboutImg} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 colsm-12">
                            <div className="wpo-about-text">
                                <div className="wpo-section-title">
                                    <span>About Us</span>
                                    <h2>Seeking of knowledge is a duty of every Muslim</h2>
                                </div>
                                <p>The Montreal West Island Halaqah serves as a conduit for the youth of our community to delve into the depths of Islamic teachings, engage in enlightening discussions, participate in engaging activities and forge a bond of unity and mutual understanding. Nestled in the serene ambiance of CIC Al Jamieh, our Halaqahs are more than just a weekly gathering; they are a haven of spiritual exploration, a hub of youthful camaraderie, and a catalyst for nurturing a generation grounded in faith, wisdom, and compassion.</p>
                                <div className="btns">
                                    <Link onClick={ClickHandler} to="/about" className="theme-btn" tabIndex="0">Discover More</Link>
                                    <ul>
                                        <li className="video-holder">
                                            <VideoModal/>
                                        </li>
                                        <li className="video-text">
                                            Watch Our Video
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="timer-section">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="timer-text">
                                <h2>Prayer Times</h2>
                                <p>Prayer times at Canadian Islamic Centre (Al-Jamieh)</p>
                                <span>Last updated Oct 15, 2023</span>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1">
                            <div className="timer-num">
                                <ul>
                                    <li>Fajr<span>06:00</span></li>
                                    <li>Dhuhr<span>13:15</span></li>
                                    <li>Asr<span>16:45</span></li>
                                    <li>Isha'a<span>19:45</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="timer-shape1">
                        <img src={tmr1} alt="" />
                    </div>
                    <div className="timer-shape2">
                        <img src={tmr2} alt="" />
                    </div>
                    <div className="timer-shape3">
                        <img src={tmr3} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;