import React from 'react'
import {Link} from 'react-router-dom'
import './style.css'

const Hero = (props) => {

    return(
        <section  className={`hero ${props.HeroStyleClass}`}>
            <div className="hero-slider">
                <div className="slide">
                    <div className="container">
                        <div className="row">
                            <div className="col col-lg-7 slide-caption">
                                <div className="slide-top">
                                    <span>Let’s Learn Islam</span>
                                </div>
                                <div className="slide-title">
                                    <h2>Nurture Faith, Foster Community</h2>
                                </div>
                                <div className="slide-subtitle">
                                    <p>Ignite Conversations and Forge Friendships - Discover the Halaqah Experience!</p>
                                </div>
                                <div className="routine-table">
                                    <ul className="routne-head">
                                        <li></li>
                                        <li>Day</li>
                                        <li>Time</li>
                                        <li>Location</li>
                                    </ul>
                                    <ul>
                                        <li>Brothers Halaqah</li>
                                        <li>Every Friday</li>
                                        <li>After Ishaa</li>
                                        <li>Canadian Islamic Centre (Al-Jamieh)</li>
                                    </ul>
                                    <ul>
                                        <li>Sisters Halaqah</li>
                                        <li>Every Saturday</li>
                                        <li>After Ishaa</li>
                                        <li>-</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right-vec">
                        <img src={props.heroImg} alt=""/>
                        <div className="right-border">
                            <div className="right-icon"><i className="fi flaticon-quran"></i></div>
                            <div className="right-icon"><i className="fi flaticon-taj-mahal-1"></i></div>
                            <div className="right-icon"><i className="fi flaticon-allah-word"></i></div>
                            <div className="right-icon"><i className="fi flaticon-muhammad-word"></i></div>
                            <div className="right-icon"><i className="fi flaticon-prayer"></i></div>
                            <div className="right-icon"><i className="fi flaticon-business-and-finance"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero;