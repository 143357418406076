import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Homepage from '../HomePage'
import AboutPage from '../AboutPage'
import EventPage from '../EventPage'
import EventPageSingle from '../EventPageSingle'
import DonatePage from '../DonatePage'
import ErrorPage from '../ErrorPage'  
import ContactPage from '../ContactPage'
      

const AllRoute = () => { 

  return (
    <div className="App">
       <Router>
          <Switch>
            <Route exact path='/' component={Homepage}/>
            <Route path='/home' component={Homepage} />
            <Route path='/about' component={AboutPage} />
            <Route path='/event' component={EventPage}/>
            <Route path='/event-single' component={EventPageSingle}/>
            <Route path='/donate' component={DonatePage}/>
            <Route path='/404' component={ErrorPage}/>
            <Route path='/contact' component={ContactPage}/>
          </Switch>
      </Router>
      
    </div>
  );
}

export default AllRoute;
